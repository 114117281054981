<template>
  <div class="vx-col w-5/6 ml-auto mr-auto">
    <span>
      <b v-if="territoryId != null">Edit Territory</b>
      <b v-else>Create Territory</b>
    </span>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <label class="vs-input--label">Territory Area</label>
        <multiselect
          v-model="territoryAreaSelected"
          :options="territoryAreaOptions"
          placeholder="Type to search"
          label="name"
          track-by="code"
          :max-height="125"
        >
          <span slot="noResult">Oops! No data found</span>
          <template slot="singleLabel" slot-scope="props">
            <span class="option__desc">
              <span class="option__title"
                >{{ props.option.code }} {{ props.option.name }}</span
              >
            </span>
          </template>
          <template slot="option" slot-scope="props">
            <div class="option__desc">
              <span class="option__title"
                >{{ props.option.code }} {{ props.option.name }}</span
              >
            </div>
          </template>
        </multiselect>
        <span
          class="text-danger text-sm"
          v-show="errors.has('TerritoryArea')"
          >{{ errors.first("TerritoryArea") }}</span
        >
      </div>
    </div>
    <div class="vx-row mb-6" v-if="territoryId != null">
      <div class="vx-col w-full">
        <vs-input disabled class="w-full" label="Code" v-model="data.code" />
      </div>
    </div>
    <div class="vx-row mb-6" v-else>
      <div class="vx-col w-full">
        <vs-input
          class="w-full"
          name="Code"
          label="Code"
          v-model="data.code"
          v-validate="'required'"
        />
        <span class="text-danger text-sm" v-show="errors.has('Code')">{{
          errors.first("Code")
        }}</span>
      </div>
    </div>
    <div class="vx-row mb-6" v-if="territoryId != null && data.external_code.length > 30">
      <div class="vx-col w-full">
        <vs-input disabled class="w-full" label="External Code" v-model="data.external_code" />
      </div>
    </div>
    <div class="vx-row mb-6" v-else>
      <div class="vx-col w-full">
        <vs-input
          class="w-full"
          name="External Code"
          label="External Code"
          v-model="data.external_code"
          v-validate="'required'"
        />
        <span class="text-danger text-sm" v-show="errors.has('ExternalCode')">{{
          errors.first("Code")
        }}</span>
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <vs-input
          class="w-full"
          label="Name"
          v-validate="'required'"
          name="Name"
          v-model="data.name"
        />
        <span class="text-danger text-sm" v-show="errors.has('Name')">{{
          errors.first("Name")
        }}</span>
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <vs-input
          class="w-full"
          label="Description"
          name="Description"
          v-model="data.description"
        />
      </div>
    </div>
    <address-component
      :data="data.address"
      @data="setDataAddress"
    ></address-component>
    <div class="vx-row">
      <div class="vx-col w-full">
        <vs-button class="mr-3 mb-2" @click="handleSubmit">Submit</vs-button>
        <vs-button
          class="ml-4 mt-2"
          type="border"
          color="danger"
          v-on:click="handleClose"
          >Close</vs-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import AddressComponent from "@/components/master/Address";

export default {
  components: {
    AddressComponent,
  },
  props: {
    territoryId: {
      type: Number,
    },
    baseUrl: {
      type: String,
    },
  },

  data() {
    return this.initialState();
  },
  methods: {
    initialState() {
      return {
        territoryAreaOptions: [],
        territoryAreaSelected: null,
        data: {
          territoryAreaId: null,
          code: "",
          name: "",
          external_code: "",
          description: "",
          address: {
            address: "",
            postalCode: "",
            countryName: "",
            provinceName: "",
            cityName: "",
            districtName: "",
            subdistrictName: "",
          },
        },
      };
    },
    handleSubmit() {
      if (!this.data.territoryAreaId) {
        this.errors.add({
          field: "TerritoryArea",
          msg: "The Territory Area field is required",
        });
      } else {
        this.$validator.validateAll().then((result) => {
          if (result) {
            this.$vs.loading();
            if (this.territoryId) {
              this.putData();
            } else {
              this.postData();
            }
          }
        });
      }
    },
    paramData() {
      return {
        territory_area_id: this.data.territoryAreaId,
        code: this.data.code,
        external_code: this.data.external_code,
        name: this.data.name,
        description: this.data.description,
        country: this.data.address.countryName,
        province: this.data.address.provinceName,
        city: this.data.address.cityName,
        district: this.data.address.districtName,
        sub_district: this.data.address.subdistrictName,
        address: this.data.address.address,
        postal_code: this.data.address.postalCode,
      };
    },
    postData() {
      this.$http
        .post(this.baseUrl, this.paramData())
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.handleClose();
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "New Territory Created",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    putData() {
      this.$http
        .put(this.baseUrl + "/" + this.territoryId, this.paramData())
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.handleClose();
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "Territory Updated",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    handleClose() {
      window.scrollTo(0, 0);
      this.$emit("close");
    },
    getData() {
      this.$vs.loading();
      if (this.territoryId) {
        this.$http
          .get(this.baseUrl + "/" + this.territoryId)
          .then((resp) => {
            this.$vs.loading.close();
            if (resp.code == 200) {
              this.data.territoryAreaId = resp.data.territory_area_id;
              this.data.code = resp.data.code;
              this.data.external_code = resp.data.external_code;              
              this.data.name = resp.data.name;
              this.data.description = resp.data.description;

              this.data.address.address = resp.data.territory_geo.address;
              this.data.address.postal_code =
                resp.data.territory_geo.postal_code;
              this.data.address.countryName = resp.data.territory_geo.country;
              this.data.address.provinceName = resp.data.territory_geo.province;
              this.data.address.cityName = resp.data.territory_geo.city;
              this.data.address.districtName = resp.data.territory_geo.district;
              this.data.address.subdistrictName =
                resp.data.territory_geo.sub_district;

              this.$http
                .get(this.baseUrl + "-area/" + this.data.territoryAreaId)
                .then((resp) => {
                  this.$vs.loading.close();
                  if (resp.code == 200) {
                    this.territoryAreaSelected = resp.data;
                  } else {
                    this.$vs.notify({
                      color: "danger",
                      title: "Error",
                      text: resp.message,
                      position: "top-right",
                      iconPack: "feather",
                      icon: "icon-x-circle",
                    });
                  }
                })
                .catch((error) => {
                  this.$vs.loading.close();
                  console.log(error);
                });
            } else {
              this.$vs.notify({
                color: "danger",
                title: "Error",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
            }
          })
          .catch((error) => {
            this.$vs.loading.close();
            console.log(error);
          });
      }
    },
    getAllDataTerritoryArea() {
      this.$vs.loading();
      this.$http
        .get(this.baseUrl + "-area", {
          params: {
            order: "code",
            type: "Sales",
          },
        })
        .then((resp) => {
          if (resp.status == "success") {
            this.territoryAreaOptions = resp.data.records;
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
            console.log(resp.data);
          }
        });
    },
    setDataAddress(val) {
      this.data.address = val;
    },
    getCode() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/setting/master-number-code/ST")
        .then((resp) => {
          if (resp.status == "success") {
            this.data.code = resp.data;
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
  },
  mounted() {
    this.getCode();
    this.getAllDataTerritoryArea();
    if (this.territoryId) {
      this.getData();
    }
  },
  computed: {},
  watch: {
    territoryId(v) {
      if (v) {
        this.getData();
      } else {
        Object.assign(this.$data, this.initialState());
      }
      this.getAllDataTerritoryArea();
    },
    territoryAreaSelected(val) {
      if (val) {
        this.errors.clear();
        this.data.territoryAreaId = val.id;
      } else {
        this.data.territoryAreaId = null;
      }
    },
  },
};
</script>
